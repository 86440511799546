<template>
  <b-container>
    <transition name="slide">
      <div class="mt-4" v-if="isVisible">
        <div class="text-header">Agora você também fica coberto!</div>
        <div class="text-desc">
          Chegou o seguro de acidentes pessoais para ciclistas! E o melhor, tudo na mesma
          assinatura e sem burocracia!
        </div>
      </div>
    </transition>

    <div class="swiper-button-prev" @click="prevSlide">
      <svg width="10" height="19" class="mr-4" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.38685 12.1612L7.50685 18.2812C7.75667 18.5296 8.0946 18.6689 8.44685 18.6689C8.7991 18.6689 9.13704 18.5296 9.38685 18.2812C9.51182 18.1573 9.61102 18.0098 9.67871 17.8473C9.7464 17.6848 9.78125 17.5106 9.78125 17.3346C9.78125 17.1585 9.7464 16.9843 9.67871 16.8218C9.61102 16.6593 9.51182 16.5118 9.38685 16.3879L3.25352 10.2812C3.12855 10.1573 3.02935 10.0098 2.96166 9.84732C2.89397 9.68484 2.85912 9.51057 2.85912 9.33455C2.85912 9.15854 2.89397 8.98426 2.96166 8.82179C3.02935 8.65931 3.12855 8.51184 3.25352 8.38789L9.38685 2.28122C9.63793 2.03192 9.77968 1.69309 9.78093 1.33927C9.78218 0.985451 9.64282 0.645626 9.39352 0.394554C9.14422 0.143484 8.80539 0.00172988 8.45157 0.000478628C8.09775 -0.000770716 7.75792 0.138586 7.50685 0.38789L1.38685 6.50789C0.637782 7.25789 0.217036 8.27455 0.217036 9.33455C0.217036 10.3946 0.637782 11.4112 1.38685 12.1612Z"
          fill="white" />
      </svg>
    </div>

    <div class="swiper-button-next" @click="nextSlide">
      <svg width="10" height="19" class="ml-4" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.61315 6.50772L2.49315 0.387723C2.24333 0.139389 1.9054 0 1.55315 0C1.2009 0 0.862964 0.139389 0.613148 0.387723C0.488177 0.511674 0.388984 0.659142 0.321293 0.821621C0.253601 0.9841 0.21875 1.15837 0.21875 1.33439C0.21875 1.51041 0.253601 1.68468 0.321293 1.84716C0.388984 2.00964 0.488177 2.15711 0.613148 2.28106L6.74648 8.38772C6.87145 8.51167 6.97065 8.65914 7.03834 8.82162C7.10603 8.9841 7.14088 9.15837 7.14088 9.33439C7.14088 9.51041 7.10603 9.68468 7.03834 9.84716C6.97065 10.0096 6.87145 10.1571 6.74648 10.2811L0.613148 16.3877C0.362076 16.637 0.220323 16.9759 0.219073 17.3297C0.217823 17.6835 0.357179 18.0233 0.606482 18.2744C0.855786 18.5255 1.19462 18.6672 1.54843 18.6685C1.90225 18.6697 2.24208 18.5304 2.49315 18.2811L8.61315 12.1611C9.36222 11.4111 9.78296 10.3944 9.78296 9.33439C9.78296 8.27439 9.36222 7.25773 8.61315 6.50772Z"
          fill="white" />
      </svg>
    </div>

    <transition name="slide">
      <div v-if="isVisible">
        <swiper ref="mySwiper" :slides-per-view="1" :space-between="30" :loop="true" :effect="'cards'"
          @swiper="onSwiper" @slideChange="onSlideChange">
          <swiper-slide v-for="(n, index) in priceOptions" :key="index" class="test" :class="{ test_2: true }">
            <div v-if="n.id === 1" class="text-recomend">Recomendado</div>
            <div v-if="n.id === 1" class="centered"></div>
            <div :class="['card-container', { 'card-activated': n.id === planeSelect }]">
              <div class="price-check">
                <div class="price-box" :class="{ 'price-box-activated': n.id === planeSelect }">
                  <div :class="{
                    'price-text-small': true,
                    'price-text-small-activated': n.id === planeSelect,
                  }">
                    R$
                  </div>
                  <div :class="{
                    'price-text': true,
                    'price-text-activated': n.id === planeSelect,
                  }">
                    {{ convertToCents(n.price) }}
                  </div>
                  <div :class="{
                    'price-text-small': true,
                    'price-text-small-activated': n.id === planeSelect,
                  }">
                    /{{ n.type }}
                  </div>
                </div>
                <button @click="handlePickOption(n.id)" :class="{
                  'card-choose-btn-check': true,
                  'card-choose-btn-check-activated': n.id === planeSelect,
                }">
                  <svg v-show="n.id === planeSelect" width="24" height="18" class="mr-2" viewBox="0 0 26 19" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.39496 19C7.65692 19.0003 6.94912 18.7034 6.42769 18.1746L0.479982 12.156C-0.159994 11.5079 -0.159994 10.4574 0.479982 9.80933C1.12016 9.16147 2.1579 9.16147 2.79808 9.80933L8.39496 15.4751L23.2019 0.48589C23.8421 -0.161963 24.8798 -0.161963 25.52 0.48589C26.16 1.13395 26.16 2.18445 25.52 2.83252L10.3622 18.1746C9.84079 18.7034 9.13299 19.0003 8.39496 19Z"
                      fill="#336D22" />
                  </svg>
                </button>
              </div>

              <div class="price-check">
                <div class="price-check">
                  <div class="label-price">Indenização</div>
                  <div :class="{
                    'chip-price': true,
                    'chip-price-activated': n.id === planeSelect,
                  }">
                    R$ {{ n.value }}
                  </div>
                </div>
              </div>

              <button v-if="n.id !== planeSelect" @click="handlePickOption(n.id)" class="card-choose-btn">
                Adicionar opcional
              </button>

              <div class="btn-footer" v-if="n.id === planeSelect">
                <button @click="handlePickOption(n.id)" class="card-choose-btn-selected">
                  Opcional adcionado
                </button>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </transition>

    <transition name="slide">
      <div v-if="isVisible">
        <div class="text-header-info">Aplicada no caso:</div>
        <div class="text-desc-info">Morte por acidente de bike</div>
        <div class="text-desc-info">
          Invalidez permante total ou parcial por acidente de bike
        </div>
        <div class="text-desc-info">
          Despesas médico-hospitalares em decorrência de um acidente de bike
        </div>
      </div>
    </transition>

    <button id="footerButton" class="footer-button-back" @click="handleBaCK">
      Voltar
    </button>
    <b-button :disabled="loading" @click="handleClick" class="footer-button">{{
      isSelected ? "Avançar" : "Seguir desprotegido(a)"
    }}</b-button>
  </b-container>
</template>
<script>
import * as D from "@/duck";
import { Swiper, SwiperSlide } from "swiper-vue2";
import "swiper/swiper-bundle.css";
import config from "../../config";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      config,
      PRICE_OPTIONS_SAFE_YEARLY: D.constants.PRICE_OPTIONS_SAFE_YEARLY,
      PRICE_OPTIONS_SAFE_MONTHLY: D.constants.PRICE_OPTIONS_SAFE_MONTHLY,
      selected: this.data.tipoAssinatura,
      planeSelect: null,
      mySwiper: null,
      receivedConstant: null,
      isSelected: false,
      isVisible: false,
      filteredOptions: [],
      fields: {
        id: "",
        checked: "",
      },
    };
  },
  computed: {
    priceOptions() {
      switch (this.selected) {
        case config.ID_TIPO_ASSINATURA_ANUAL:
          return this.PRICE_OPTIONS_SAFE_YEARLY;
        case config.ID_TIPO_ASSINATURA_SEMESTRAL:
          return this.PRICE_OPTIONS_WEEK;
        case config.ID_TIPO_ASSINATURA_MENSAL:
          return this.PRICE_OPTIONS_SAFE_MONTHLY;
        default:
          return this.PRICE_OPTIONS_SAFE_YEARLY;
      }
    },
  },
  methods: {
    handleClick() {
      this.emitPricingData();
      this.clickHandler();
    },
    handleBaCK() {
      this.previousStep();
    },
    onSwiper(swiper) {
      this.mySwiper = swiper;
    },
    onSlideChange() { },
    selectOption(option) {
      this.selected = option;
    },
    selectPlane(id) {
      this.planeSelect = id;
      this.isSelected = true;
    },
    selectRemovePlane() {
      this.planeSelect = null;
      this.isSelected = false;
    },

    handlePickOption(id) {
      if (this.isSelected === true && id === this.planeSelect) {
        this.selectRemovePlane();
      } else if (this.isSelected === true && id !== this.planeSelect) {
        this.selectPlane(id);
      } else {
        this.selectPlane(id);
      }
    },
    emitPricingData() {
      this.$emit("safeData", {
        select: this.isSelected,
        planeValue: this.priceOptions.find((e) => e.id === this.planeSelect),
      });
    },
    initializeFields() {
      var plans = this.data.plans;

      this.PRICE_OPTIONS_SAFE_YEARLY = D.constants.PRICE_OPTIONS_SAFE_YEARLY.map((plan, index) => ({
        ...plan,
        id: plans[index].id,
        price: plans[index].valorAnualComAssistencia24h
      }));

      this.PRICE_OPTIONS_SAFE_MONTHLY = D.constants.PRICE_OPTIONS_SAFE_MONTHLY.map((plan, index) => ({
        ...plan,
        id: plans[index].id,
        price: plans[index].valorMensalComAssistencia24h
      }));

      this.planeSelect = this.data.planeValue?.id || config.ID_PLANO_OURO;
      this.isSelected = this.data?.select || true;

    },
    prevSlide() {
      if (this.mySwiper) {
        this.mySwiper.slidePrev();
      }
    },
    nextSlide() {
      if (this.mySwiper) {
        this.mySwiper.slideNext();
      }
    },
    convertToCents(price) {
      return price.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
    },
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
  },
  watch: {

  },
};
</script>
<style scoped>
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.title-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  border-radius: 0px !important;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 35%;
  width: 50px;
  height: 66px;
  background-color: #78849e80;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  display: none;
}

.swiper-button-prev {
  left: 0px;
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
}

.swiper-button-next {
  right: 0px;
  clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 50% 100%);
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.btn-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chip-price {
  background-color: #78849e;
  padding: 5px;
  font-size: 20px;
  width: 100%;
  color: #ffffff;
  margin-left: 4px;
  border-radius: 10px;
  text-align: center;
}

.chip-price-activated {
  background-color: #a6e894;
  padding: 5px;
  font-size: 20px;
  width: 100%;
  color: #3a7529;
  margin-left: 4px;
  border-radius: 10px;
  text-align: center;
}

.price-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-text {
  align-items: center;
  text-align: center;
  color: #78849e;
  font-weight: bold;
  font-size: 42px;
}

.price-text-small {
  align-self: center;
  margin-top: 13px;
  color: #78849e;
  font-weight: bold;
  font-size: 14px;
}

.price-text-activated {
  align-items: center;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
  font-size: 42px;
}

.price-text-small-activated {
  align-self: center;
  margin-top: 13px;
  color: #ffffff;
  font-weight: bold;
  font-size: 14px;
}

.label-price {
  font-size: 18px;
}

.btn-div {
  align-items: space-between;
  padding: 12px;
}

.line-through {
  text-decoration: line-through;
  color: rgb(156, 156, 156);
}

.line-through-selected {
  text-decoration: line-through;
  color: #91c882;
}

.card-container {
  background-color: #fff;
  color: #425994;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.5s ease, color 0.5s ease, box-shadow 0.5s ease;
}

.card-activated {
  background-color: #4fa935;
  color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.divider {
  background-color: #ccc;
  height: 1px;
  margin: 10px 0;
}

.divider-activated {
  background-color: #91c882;
  height: 1px;
  margin: 10px 0;
}

.card-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}

.text-header {
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #5db247;
}

.text-header-info {
  margin-top: 50px;
  text-align: left;
  font-size: 13px;
  margin-bottom: 20px;
  font-weight: bold;
  color: #78849e;
}

.text-desc-info {
  margin-left: 20px;
  /* Espaçamento para o ponto */
  color: #425994;
  font-size: 14px;
  position: relative;
  /* Para posicionar o ponto */
}

.text-desc-info::before {
  content: "•";
  /* Adiciona o ponto */
  position: absolute;
  left: -20px;
  /* Posiciona o ponto à esquerda */
}

.text-recomend {
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  color: #4fa935;
}

.text-desc {
  text-align: center;
  font-size: 13px;
  margin-bottom: 20px;
  color: #425994;
}

.card-list {
  list-style-type: none;
  padding-left: 0;
}

.card-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.card-list-item::before {
  content: "●";
  color: #4caf50;
  margin-right: 10px;
}

.card-benefits {
  color: #4caf50;
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.card-benefits::before {
  content: "●";
  color: #4caf50;
  margin-right: 10px;
}

.card-choose-btn {
  background-color: #fff;
  color: #425994;
  border: 1px solid #425994;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 60px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.card-choose-btn-selected {
  background-color: #4caf50;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 5px;
  padding: 10px 20px;
  width: 100%;
  height: 60px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 20px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.card-choose-btn-check {
  background-color: #ffffff;
  color: #4caf50;
  border: 1px solid #425994;
  border-radius: 20px;
  font-weight: bold;
  padding: 10px 14px;
  width: 51px;
  height: 51px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.card-choose-btn-check-activated {
  background-color: #a6e894;
  color: #4caf50;
  border: 1px solid #4caf50;
  border-radius: 20px;
  font-weight: bold;
  padding: 10px 14px;
  width: 51px;
  height: 51px;
  display: block;
  text-align: center;
  text-decoration: none;
}

.btn-checkd,
.btn-noCheck {
  transition: background-color 0.8s ease, color 0.8s ease, border-color 0.8s ease;
}

.btn-checkd {
  background-color: #78849e;
  color: #fafafa;
  border: solid 1px #78849e;
  border-radius: 10px;
  padding: 8px;
  font-size: 12px;
  font-weight: bold;
  width: 90px;
  height: 50px;
}

.btn-noCheck {
  background-color: #fafafa;
  color: #78849e;
  border: solid 1px #dadada;
  font-size: 12px;
  font-weight: bold;
  border-radius: 10px;
  padding: 8px;
  width: 90px;
  height: 50px;
}

.title-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  color: #919ab0;
}
</style>
